/* Base Styles */
.w-layout-grid {
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-rows: auto auto;
  grid-row-gap: 0;
  grid-column-gap: 16px;
}

.product5-grid {
  position: relative;
  display: grid;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  grid-auto-flow: row;
  grid-auto-columns: 1fr;
  grid-column-gap: 36px;
  grid-row-gap: 0;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: auto;
}

.home-sections-label {
  position: sticky;
  top: 210px;
  bottom: 0;
}

.left-section-fixed {
  grid-row-start: span 1;
  grid-column-start: span 4;
  align-self: start;
}

.homepage-layouts-grid {
  grid-column-gap: 36px;
  grid-row-gap: 0;
  grid-template-rows: auto;
}

.right-section-fixed {
  grid-row-start: 1;
  grid-row-end: 3;
  grid-column-start: 5;
  grid-column-end: 13;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .w-layout-grid {
    grid-template-columns: 1fr;
    grid-column-gap: 8px;
  }
  .home-sections-label {
    position: initial;
    z-index: 1;
  }

  .product5-grid {
    grid-template-columns: 1fr;
    grid-column-gap: 16px;
  }

  .left-section-fixed,
  .right-section-fixed {
    grid-row-start: auto;
    grid-column-start: auto;
    grid-column-end: auto;
  }

  .home-sections-label {
    top: 100px;
  }

  .homepage-layouts-grid {
    grid-column-gap: 16px;
  }
}
