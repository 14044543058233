@tailwind base;
@tailwind components;
@tailwind utilities;

@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";
@import url("https://fonts.googleapis.com/css2?family=Old+Standard+TT:ital,wght@0,400;0,700;1,400&family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
html {
  font-size: 18px;
}

*,
a {
  outline: 0;
  text-decoration: none;
  color: inherit;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
#root{
  background-color: #111;
}

img {
  max-width: 100%;
}
svg {
  max-width: 100%;
  width: 100%;
  height: auto;
}

html {
  font-size: 18px;
}

/* Slick slider */
/* Arrows */
/* Slider */
.slick-slider {
  position: relative;

  display: block;
  box-sizing: border-box;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;

  display: block;
  overflow: hidden;

  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;

  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before,
.slick-track:after {
  display: table;

  content: "";
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;

  height: 100%;
  min-height: 1px;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;

  height: auto;

  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* Arrows */
.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;

  position: absolute;
  top: 50%;

  display: block;

  width: 20px;
  height: 20px;
  padding: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);

  cursor: pointer;

  color: transparent;
  border: none;
  outline: none;
  background: transparent;
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  color: transparent;
  outline: none;
  background: transparent;
}

.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}

.project_amnities .slick-dots {
  left: -10%;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
  background: var(--purple-color);
  border-radius: 50%;
  opacity: 0.5;
}

.slick-dots li.slick-active {
  opacity: 1;
}

.slick-dots li button {
  font-size: 0;
  line-height: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.package_Scn {
  padding-bottom: 20px;
}

.package .web-container {
  overflow: visible;
}

/* slick slider */
.slick-prev:before,
.slick-next:before {
  content: "";
  background: url(./image/left-arrow.2510992d0680b7d6c49d.webp);
  height: inherit;
  width: inherit;
  /* background-position: center; */
  background-size: auto;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.4;
}

.slick-next:before {
  rotate: 180deg;
}

.slick-prev {
  left: -5%;
}

.slick-prev,
.slick-next {
  height: 70px;
  width: 70px;
  z-index: 2;
}

.slick-next {
  right: -5%;
}

.scroll-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  will-change: transform;
}

body::-webkit-scrollbar {
  width: 5px;
  /* Adjust the width as needed */
}

body::-webkit-scrollbar-thumb {
  background-color: #222423;
  /* Change this to your desired thumb color */
  border-radius: 2px;
}

body::-webkit-scrollbar-track {
  background-color: #f6f6f6;
  /* Change this to your desired track color */
}

.mobile_show {
  display: none;
}

@media all and (max-width: 1440px) {
  .slick-prev,
  .slick-next {
    height: 50px;
    width: 50px;
    z-index: 2;
  }
  .slick-prev:before,
  .slick-next:before {
    background-size: contain;
  }
  html {
    font-size: 17px;
  }
}
@media all and (max-width: 1300px) {
  section {
    padding: 45px 0;
  }
  html {
    font-size: 16px;
  }
}
@media all and (max-width: 680px) {
  .slick-prev,
  .slick-next {
    height: 35px;
    width: 20px;
    z-index: 2;
  }
  .slick-prev {
    left: -7%;
  }
  .slick-next {
    right: -7%;
  }
  .slick-prev:before,
  .slick-next:before {
    background-size: contain;
  }
}
.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 0.4;
}
/* loader css */


/* From Uiverse.io by Leoodaviid */ 
.loader-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.loader {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  -webkit-animation: spin 1s linear infinite;
          animation: spin 1s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-box-shadow: 0 -1px 0 rgba(255, 255, 255, 0.4);
            box-shadow: 0 -1px 0 rgba(255, 255, 255, 0.4);
  }

  50% {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    -webkit-box-shadow: 0 -1px 0 rgba(255, 255, 255, 0.4);
            box-shadow: 0 -1px 0 rgba(255, 255, 255, 0.4);
  }

  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
    -webkit-box-shadow: 0 -1px 0 rgba(255, 255, 255, 0.4);
            box-shadow: 0 -1px 0 rgba(255, 255, 255, 0.4);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-box-shadow: 0 -1px 0 rgba(255, 255, 255, 0.4);
            box-shadow: 0 -1px 0 rgba(255, 255, 255, 0.4);
  }

  50% {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    -webkit-box-shadow: 0 -1px 0 rgba(255, 255, 255, 0.4);
            box-shadow: 0 -1px 0 rgba(255, 255, 255, 0.4);
  }

  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
    -webkit-box-shadow: 0 -1px 0 rgba(255, 255, 255, 0.4);
            box-shadow: 0 -1px 0 rgba(255, 255, 255, 0.4);
  }
}